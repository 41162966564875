@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 768px) {
  .responsive-text {
    font-size: 0.875rem; /* Adjust font size to smaller screens */
  }
  .responsive-logo {
    width: 20vw; /* Adjust logo size for smaller screens */
  }
}
